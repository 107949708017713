import React, { Component, useEffect } from "react";

import { Widget, addResponseMessage } from "react-chat-widget";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import "./App.scss";
import logo from "./logo.svg";

import "react-chat-widget/lib/styles.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));
const AgriInputForm = React.lazy(() =>
  import("./views/AgriInputForm/AgriInputForm")
);

// useEffect(() => {
//   // addResponseMessage('Welcome to this **awesome** chat!');

// }, []);

const handleNewUserMessage = (newMessage) => {
  console.log(`New message incoming! ${newMessage}`);
  // Now send the message throught the backend API
  addResponseMessage("Hello");
};

class App extends Component {
  render() {
    return (
      <>
        {/* <div className="App"> */}
        {/* <Widget 
          handleNewUserMessage={handleNewUserMessage}
          profileAvatar={logo}
          title="Sulekha 🤖"
          subtitle="HFN Bot Internal Testing"
        /> */}
        {/* </div> */}
        <ToastContainer theme={"colored"} />
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
              <Route
                exact
                path="/AgriInputOrder/Form"
                name="Create orders"
                render={(props) => <AgriInputForm {...props} />}
              />
              <Route
                path="/"
                name="Home"
                render={(props) => {
                  return localStorage.getItem("_token") !== undefined &&
                    localStorage.getItem("_token") !== null ? (
                    <DefaultLayout {...props} />
                  ) : (
                    <Redirect to="/login" />
                  );
                }}
              />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </>
    );
  }
}

export default App;
